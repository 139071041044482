import { withRouter } from "react-router";
import { connect } from 'react-redux';

import gameService from '../../services/GameService';
import BaseDashboardView from './BaseDashboardView';
import ErrorBox from "../../components/ErrorBox";
import { Col, Container, Row } from "react-bootstrap";
import CountDownTimer from "./components/CountDownTimer";
import TeamDetails from "./components/TeamDetails";
import DashboardActions from "./components/DashboardActions";
import RegulatorReport from "./components/RegulatorReport";
import TurnSelect from "./decisions/components/TurnSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { Fragment, createRef } from "react";
import { formatPercentage } from "../../controls/Percentage";
import { formatCurrency } from "../../controls/Currency";
import { KPI_FORMATS } from "./components/KPI";
import { KPI_TYPES } from "./ManagementRatiosView";
import TeamYTDKeyIndicators from "./components/TeamYTDKeyIndicators";
import TeamYTDKeyIndicatorsGraph from "./components/TeamYTDKeyIndicatorsGraph";

/**
 * The Regulator Report class.
 */
class RegulatorReportView extends BaseDashboardView
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom
        super(props, "Regulator Report", KPI_TYPES);

        // Create the reference
        this.printComponentRef = createRef();

        // Initialize kthe selected turn
        this.state =
        {
            selectedTurn: props.game ? props.game.turns_completed + 1 : -1
        };

        // Initialize the loaded flag
        this.loaded= false;

        // The maximum number of graph columns
        this.maxGraphCols = 1;

        // // Get the dashboard details
        // if (props.game && this.props.team && props.team.regulatory_report_turns && props.team.regulatory_report_turns.length)
        // {
        //     // Get the regulator report
        //     gameService.getRegulatoryReport(props.game.game_id, props.team.team_id, props.team.regulatory_report_turns[props.team.regulatory_report_turns.length - 1]);

        //     // Update the state
        //     this.state.requested    = true;
        //     this.state.selectedTurn = props.team.regulatory_report_turns[props.team.regulatory_report_turns.length - 1];
        // }
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        let turns;
        let turnNum;

        // Initialize the regulator report & community averages
        let regulatorReportMap = null;
        let communityAverages = null;

        // Get the dashboard details
        let dashboardDetails = state.games.regulatoryReportMap[ownProps.match.params.team_id];

        // Get the regulator report....
        if (dashboardDetails && dashboardDetails.turns && (dashboardDetails.turns.length > 0))
        {
            // Set the turns
            turns = dashboardDetails.turns;

            // Get the dashboard details
            if (dashboardDetails.regulatoryReport && dashboardDetails.regulatoryReport.regulatorReport &&
                (dashboardDetails.regulatoryReport.regulatorReport.length > 0))
            {
                // Save the turn
                turnNum = turns[turns.length - 1];

                // Initalize the regulator report map
                regulatorReportMap = {};

                // Populate the map
                for (let row of dashboardDetails.regulatoryReport.regulatorReport)
                {
                    // Get the item
                    let item = regulatorReportMap[row.name];

                    // Add it if necessary
                    if (!item)
                    {
                        // Initialize the item
                        item = [];

                        // Put it into the map
                        regulatorReportMap[row.name] = item;
                    }

                    // Push the row
                    item.push(row.value);
                }

                communityAverages = dashboardDetails.regulatoryReport.communityAverages;
                dashboardDetails  = dashboardDetails.regulatoryReport.dashboardDetails;
            }
            else
                dashboardDetails = null;
        }
        else
            turns = [];

        // Call the base class...
        let result = BaseDashboardView.mapStateToProps(state, ownProps, dashboardDetails);

        // Save the regulator report map
        result.regulatorReportMap = regulatorReportMap;

        // Set the turns
        result.turns = turns;

        // Set the turn
        result.turnNum = turnNum;

        // Create the community average map
        let communityAverageMap = {};

        // Add the averages
        if (communityAverages)
        {
            for (let communityAverage of communityAverages)
                communityAverageMap[communityAverage.type + "." + communityAverage.name + "." + communityAverage.quarter_offset] = communityAverage.value;
        }

        // Get user's role
        result.role = state.authentication.role;

        // Initialize the regulator map
        result.regulatorMap = {};

        // Add the items to the map...
        if (result.kpiMap && Object.keys(result.kpiMap).length)
        {
            for (let key in result.kpiMap)
            {
                // Check each KPI
                for (let kpi of result.kpiMap[key].kpis)
                {
                    // Get the value
                    let value = kpi.quarters[kpi.quarters.length - 1];

                    // Build the community quarterss
                    kpi.communityQuarters = [];
                    for (let counter = 0; counter < kpi.quarters.length - 1; counter++)
                        kpi.communityQuarters[counter] = communityAverageMap[key + "." + kpi.name + "." + (counter - (kpi.quarters.length - 1))];

                    // Format it...
                    if (kpi.format === KPI_FORMATS.PERCENTAGE)
                        value = formatPercentage(value);
                    else
                        value = formatCurrency(value);

                    // Place the value into the map
                    result.regulatorMap[kpi.name] = value;
                }
            }
        }

        
        // Get out...
        return result;
    }


    componentDidMount()
    {
        super.componentDidMount();

        // Get the dashboard details
        if (this.props.game && this.props.team && this.props.team.regulatory_report_turns && this.props.team.regulatory_report_turns.length)
        {
            // Get the regulator report
            gameService.getRegulatoryReport(this.props.game.game_id, this.props.team.team_id, this.props.team.regulatory_report_turns[this.props.team.regulatory_report_turns.length - 1]);

            // Update the state
            this.setState({ requested: true, selectedTurn: this.props.team.regulatory_report_turns[this.props.team.regulatory_report_turns.length - 1] });
        }
    }


    /**
     * This method handles component updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if there is nothing to do...
        if (!this.props.game || !this.props.team)
            return;

        // Get the previous team ID
        let previousTeamId = prevProps.team ? prevProps.team.team_id : null;
        let prevReportTurns = prevProps.team ? prevProps.team.regulatory_report_turns : null;

        // See if the team ID changed...
        if (((this.props.team.team_id !== previousTeamId) || !prevReportTurns) && this.props.team.regulatory_report_turns && this.props.team.regulatory_report_turns.length)
        {
            // Get the regulator report
            gameService.getRegulatoryReport(this.props.game.game_id, this.props.team.team_id, this.props.team.regulatory_report_turns[this.props.team.regulatory_report_turns.length - 1]);

            // Update the state
            this.setState({ requested: true, selectedTurn: this.props.turnNum });
        }
        else
        {
            // Update the turn
            if (this.props.turnNum !== prevProps.turnNum)
            {
                this.setState({ selectedTurn: this.props.turnNum });
            }
        }
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Parse the value...
        value = parseInt(value);

        // Get the regulator report
        gameService.getRegulatoryReport(this.props.game.game_id, this.props.team.team_id, value);

        // Update the state
        this.setState({ selectedTurn: value, requested: true });
    }


    /**
     * This method gets the print component reference.
     * 
     * @returns The print componnent reference.
     */
    getPrintComponentRef = () =>
    {
        return this.printComponentRef.current;
    }


    /**
     * This method creates the print trigger.
     * 
     * @returns The print trigger.
     */
    createPrintTrigger = () =>
    {
        return <FontAwesomeIcon icon={ faPrint } size="1x" className="decision-icon-color-small" />
    }


    /**
     * This method cerates the statistics.
     * 
     * @param {*} name The name.
     * @returns The statistics.
     */
    createStats(name)
    {
        // Get out if nothing to do...
        if (!this.props.regulatorMap || !this.props.regulatorMap[name])
            return { name: name, value: "" }

        // REturn the statistics...
        return { name: name, value: this.props.regulatorMap[name] }
    }




    /**
     * This method renders the key indicators.
     * 
     * @returns The key indicators.
     */
    renderKeyIndicators()
    {
        return (
            <Fragment>
                { 
                    this.props.regulatorReportMap ?
                        <Fragment>
                            <Container className="regulator-key-indicators">
                                <Row>
                                    <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2} className="key-indicator">
                                        I & P - 200  
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2} className="key-indicator">
                                        I & P 200
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                                        EVE
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2}>
                                        { formatPercentage(this.props.regulatorReportMap["EVE"][0]) }
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2}>
                                        { formatPercentage(this.props.regulatorReportMap["EVE"][1]) }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                                        EAR
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2}>
                                        { formatPercentage(this.props.regulatorReportMap["EAR"][0]) }
                                    </Col>
                                    <Col xs={4} sm={4} md={2} lg={2}>
                                        { formatPercentage(this.props.regulatorReportMap["EAR"][1]) }
                                    </Col>
                                </Row>
                            </Container>

                            <Container className="regulator-key-indicators">
                                <Row>
                                    <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                                    </Col>
                                    <Col xs={6} sm={5} md={6} lg={3} className="key-indicator">
                                        Current Quarter
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                                        RSA / RSL
                                    </Col>
                                    <Col xs={6} sm={6} md={3} lg={3}>
                                        { formatPercentage(this.props.regulatorReportMap["RSA/RSL"][0]) }
                                    </Col>
                                </Row>
                            </Container>
                        </Fragment>
                    : ""
                }
                <TeamYTDKeyIndicators kpiTypes={ this.kpiTypes } kpiMap={ this.props.kpiMap }/>
            </Fragment>
        )
    }

    
    /**
     * This method renders the key indicators graph.
     * 
     * @returns The key indicators graph.
     */
    renderKeyIndicatorsGraph()
    {
        return <TeamYTDKeyIndicatorsGraph kpiTypes={ this.kpiTypes } kpiMap={ this.props.kpiMap } maxGraphCols={ this.maxGraphCols }/>
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game === null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        // Get the team
        let team = this.props.team;
        if (team === null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            )
        }

        // Get the dashboard details
        let regulatorReport = this.state.regulatorReport;
        if (regulatorReport === null)
            regulatorReport = [];

        let stats = 
        {
            capital:
            [
                this.createStats("Tier 1")
            ]
        }

        return(
            <Container>
                <CountDownTimer />
                <Row className="dashboard-action-container g-1">
                    <Col xs={8} sm={8} md={10} lg={10} gu>
                        <Container className="center full-width full-height">
                            <Row className="g-1">
                                <Col xs={12} sm={12} md={12} lg={12} className="left">
                                    <TeamDetails game={ game } team={ team } />
                                </Col>
                            </Row>
                            <Row className="regulator-turn-select">
                                <Col xs={12} sm={12} md={3} lg={3} className="left">
                                    <TurnSelect name="turns" onChange={ this.onTurnChange } value={ this.state.selectedTurn } availableTurns={ this.props.turns } />
                                    <ReactToPrint
                                        content={ this.getPrintComponentRef }
                                        documentTitle={ team.team_name + " Regulator Report" }
                                        removeAfterPrint
                                        trigger={ this.createPrintTrigger }
                                        />

                                    <RegulatorReport ref={ this.printComponentRef } className="printable" readOnly="true" stats={ stats } gameId={ this.props.game.game_id } teamId={ this.props.team.team_id } turnNum={ this.state.selectedTurn } currentTurnNum={ this.props.game.turns_completed + 1 } role={ this.props.role } />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} gu>
                                    <RegulatorReport gameId={ this.props.game.game_id } teamId={ this.props.team.team_id } turnNum={ this.state.selectedTurn } currentTurnNum={ this.props.game.turns_completed + 1 } role={ this.props.role } />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} gu className="regulator-report-dashboard">
                                    { this.renderDashboard() } 
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={4} sm={4} md={2} lg={2}>
                        <DashboardActions mode={ this.mode } game={ this.props.game } team={ this.props.team } />
                    </Col>
                </Row>
            </Container>
        );
    }
}

// Export the game view...
export default withRouter(connect(RegulatorReportView.mapStateToProps)(RegulatorReportView));