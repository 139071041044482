import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import helpService from '../services/HelpService';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';



/**
  * This method maps the state to the properties.
  * 
  * @param {*} state The state.
  * @param {*} ownProps The own properties.
  * @returns The mapping.
  */
function mapStateToProps(state, ownProps)
{
    let result =
    {
        role: state.authentication.role
    };

    // Get out...
    return result;
}

/**
 * The help link component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
class HelpLink extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state =
        {
            visible: false
        }
    }


    /**
     * This method shows the modal.
     */
    showModal = () =>
    {
        // Get the active page
        let activePage = helpService.getActivePage();

        // Get out if the help has already been loaded
        if (this.state.page === activePage)
        {
            // Show the help if it exists...
            if (this.state.help)
                this.setState({ visible: true });

            // Get out...
            return;
        }

        // Reset if nothing to do...
        if (!activePage)
        {
            this.setState({ page: activePage, help: null });
            return;
        }

        // Load the help
        helpService.load(this.onHelpLoaded);
    }


    /**
     * This method handles the help loaded event.
     *
     * @param {*} response The response.
     */
    onHelpLoaded = (response) =>
    {
        this.setState({ help: response.text, page: response.page, visible: true });
    }


    /**
     * The help save handler.
     */
    onSave = () =>
    {
        // Save the help
        helpService.save(this.state.updatedHelp, this.onSaveComplete);
    }


    /**
     * This method handles the save complete event.
     */
    onSaveComplete = () =>
    {
        alert("The help updates have been saved.");

        this.setState(
        {
            help: this.state.updatedHelp,
            editing: false
        })
    }


    render()
    {
        // Get out...
        return (
            <Fragment>
                <Modal show={ this.state.visible } onHide={() => this.setState({ visible: false, editing: false }) } dialogClassName="help-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Help - { helpService.getActivePage() }</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row className="vh50">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    { 
                                        this.props.role === "Administrator" ?
                                            <div style={{ "paddingBottom": "5px" }}>
                                                <div className="fa-buttons float-right">
                                                    {
                                                        this.state.editing ?
                                                            <Fragment>
                                                                <FontAwesomeIcon icon = { faFloppyDisk } onClick={ this.onSave } />
                                                                &nbsp;&nbsp;&nbsp;
                                                            </Fragment>
                                                        :
                                                            ""
                                                    }
                                                    <FontAwesomeIcon icon = { faEdit } onClick={ () => this.setState({ editing: !this.state.editing, updatedHelp: this.state.help} ) } />
                                                </div>
                                            </div>
                                        :
                                            ""
                                    }
                                    {
                                        this.state.editing ?
                                            <ReactQuill theme="snow" className="rich-text-editor" value={ this.state.updatedHelp } onChange={ (value) => this.setState({ updatedHelp: value }) } />
                                        :
                                            <div dangerouslySetInnerHTML={ {__html: this.state.help }}></div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ visible: false, editing: false }) }>Close</Button>
                    </Modal.Footer>
                </Modal>
                
                <a href="javscript:void(0)" onClick={ this.showModal }><i class="fa fa-question-circle pad-right-5"></i>Help</a>
            </Fragment>
        );
    }
}

// Get out...
export default connect(mapStateToProps)(HelpLink);
